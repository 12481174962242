import React from "react";
import { css } from "@emotion/react";
import { Button, Go, Grid, Layout } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

import { ReactComponent as SpoonsDesktop } from "~assets/images/footer-static-desktop.svg";
import { ReactComponent as SpoonsMobile } from "~assets/images/footer-static-mobile.svg";

const NotFoundPage = () => (
  <>
    <Layout
      _css={css`
        height: 100vh;
        background: var(--color-black);
        color: var(--color-white);

        footer {
          display: none;
        }
      `}
    >
      <Grid
        _css={css`
          height: 100%;
          z-index: 10;
        `}
      >
        <section
          css={css`
            grid-column: span 12 / span 12;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
          `}
        >
          <h1 className="d1">NADA</h1>

          <p
            className="b1"
            css={css`
              margin: 2.5rem 0;
            `}
          >
            You look a little lost,
            <br />
            there’s zip zilch nada here!
          </p>

          <div
            css={css`
              width: 100%;

              ${MEDIA_QUERIES.desktop} {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            `}
          >
            <Go
              to="/"
              _css={css`
                display: block;
              `}
            >
              <Button
                _css={css`
                  width: 100%;
                  margin-bottom: 0.75rem;

                  ${MEDIA_QUERIES.desktop} {
                    width: 12rem;
                    margin: 0 0.25rem;
                  }
                `}
                color="light"
                text="TAKE ME HOME"
              />
            </Go>

            <Go
              to="/stockists/"
              _css={css`
                display: block;
              `}
            >
              <Button
                _css={css`
                  width: 100%;

                  ${MEDIA_QUERIES.desktop} {
                    width: 12rem;
                    margin: 0 0.25rem;
                  }
                `}
                color="light"
                text="FIND STOCKISTS"
              />
            </Go>
          </div>
        </section>
      </Grid>

      <div
        css={css`
          width: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
        `}
      >
        <SpoonsDesktop
          css={css`
            width: 100%;
            display: none;

            ${MEDIA_QUERIES.desktop} {
              display: block;
            }
          `}
        />
        <SpoonsMobile
          css={css`
            width: 100%;

            ${MEDIA_QUERIES.desktop} {
              display: none;
            }
          `}
        />
      </div>
    </Layout>
  </>
);

export default NotFoundPage;
